import React from 'react'
import styled from 'styled-components'
import HousesIcon from '../HousesIcon'
import ParkingIcon from '../ParkingIcon'
import ToysIcon from '../ToysIcon'

const ServicesStyled = styled.div`
  max-width: 1300px;
  margin: 0 auto 0 auto;
  margin-bottom: 8rem;
  display: grid;
  grid-template-columns: 1fr;

  @media (max-width: 576px) {
    margin-bottom: 5rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const ServiceStyled = styled.div`
  display: flex;
  margin: 2rem;

  @media (max-width: 576px) {
    margin: 2rem 2rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
  }

  .service-title {
    position: relative;
    margin-bottom: 2rem;
  }

  .number {
    position: absolute;
    left: 0;
    bottom: -0.5rem;
    font-family: 'Cormorant SC Bold', serif;
    font-size: 8rem;
    font-weight: bold;
    color: var(--light-grey);
  }

  .text {
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: var(--grey);
    max-width: 100%;
  }
`
const Services = () => (
  <ServicesStyled>
    <ServiceStyled>
      <ToysIcon />
      <div className="container">
        <div className="title service-title">
          Детская площадка
          <span className="number">01</span>
        </div>
        <div className="text">Детская игровая площадка</div>
      </div>
    </ServiceStyled>

    <ServiceStyled>
      <ParkingIcon />
      <div className="container">
        <div className="title service-title">
          автопарковка
          <span className="number">02</span>
        </div>
        <div className="text">Гарантированная стоянка автотранспорта</div>
      </div>
    </ServiceStyled>

    <ServiceStyled>
      <HousesIcon />
      <div className="container">
        <div className="title service-title">
          уютные номера
          <span className="number">03</span>
        </div>
        <div className="text">Экологически чистый район на берегу озера</div>
      </div>
    </ServiceStyled>
  </ServicesStyled>
)

export default Services
