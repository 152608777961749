import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-color: var(--yellow);
  cursor: pointer;

  div {
    width: 2.5rem;
    height: 2.5rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const ArrowRight = ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256.52 512.59"
        width="256.52"
        height="512.59"
      >
        <path d="M253.56 249.25L18.93 3.92A10.67 10.67 0 1 0 3.56 18.63l227.6 238-227.6 238a10.67 10.67 0 0 0 15.42 14.75L253.56 264a10.68 10.68 0 0 0 0-14.75z"></path>
      </svg>
    </div>
  </Wrapper>
)

export default ArrowRight
