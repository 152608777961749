import React from 'react'
import styled from 'styled-components'
import ToysImage from '../assets/images/toys.svg'

const DivStyled = styled.div`
  width: 90px;
  height: 90px;
  min-width: 90px;
  border: 2px solid var(--yellow);
  border-radius: 47px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 55px;
    height: 55px;
  }
`

const ToysIcon = () => (
  <DivStyled>
    <img src={ToysImage} />
  </DivStyled>
)

export default ToysIcon
