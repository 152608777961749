import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import FsLightbox from 'fslightbox-react'

// import ButtonLink from '../ButtonLink'
import PlayIcon from '../PlayIcon'
// import { CompanyInfo } from '../../constants'
// import aboutHeader from '../../assets/images/about-header.jpg'
import videoImg from '../../assets/images/video.jpg'
// import overview from '../../assets/video/overview.mp4'
import video from '../../assets/video/video.mp4'

const AboutStyled = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  padding: 0rem 2rem 0 2rem;
  margin-top: -2rem;

  @media (max-width: 767px) {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    margin-bottom: 3rem;
  }

  @media (min-width: 992px) {
    flex-direction: row-reverse;
    margin-bottom: 12rem;
  }

  .header-img-bg {
    position: relative;
    display: flex;
    opacity: 1;
    align-items: center;
    // transform: translateY(50px);
    height: 200px;
    background-color: var(--yellow);

    .white-bg {
      display: none;
      position: absolute;
      right: -200rem;
      top: 4rem;
      bottom: 0;
      width: 200rem;
      background-color: var(--white);
    }

    @media (min-width: 992px) {
      height: 550px;
      width: 50%;
      padding-right: 20px;
      margin-top: 5rem;
    }

    :hover {
      cursor: pointer;
    }
  }

  .header-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    margin-right: 3rem;
    background: url(${videoImg});
    background-attachment: scroll, fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (min-width: 992px) {
      height: 630px;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
      width: 50%;
      padding-right: 5rem;
    }
  }

  h1 {
    margin-top: 100px;
    margin-bottom: 4rem;
    text-align: left;

    @media (max-width: 991px) {
      margin-top: 140px;
    }
  }

  .about-button {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
  }
`

const About = () => {
  const [open, setOpen] = useState(false)

  const onShowVideo = () => {
    setOpen(!open)
  }

  return (
    <AboutStyled>
      <FsLightbox
        toggler={open}
        sources={[video]}
        customAttributes={[{ autoPlay: true, foo: 'bar' }]}
      />
      <motion.div
        className="header-img-bg"
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 40 }}
        transition={{ duration: 1 }}
        onClick={onShowVideo}
      >
        <div className="white-bg" />
        <span className="header-img">
          <PlayIcon />
        </span>
      </motion.div>

      <div className="text-wrapper">
        <h1>О нас</h1>
        <p>
          Золотая Звезда расположена поистине в самом живописном заливе с самым
          большим, пологим и удобным пляжем. Привнесенные на базу блага
          цивилизации, создающие комфорт, не нарушают целостности природы и
          позволяют чувствовать себя с ней единым целым.
        </p>
        <p>
          На сегодняшний день озеро Беле является одним из самых популярных мест
          отдыха. Это озеро – крупнейшее в Хакасии, оно расположено на холмистых
          просторах Джиримской степи. Беле состоит как бы из двух частей,
          соединенных между собой протокой. Западная половина водоема более
          пресная, в нее впадает небольшая речка Туим, в переводе с хакасского
          «туюх» – «глухой». Здесь разводят рыбу: окуня, сига, пелядь, леща,
          форель и кету. Восточная половина озера более соленая, а вода в ней по
          составу близка к морской. База отдыха Золотая Звезда приглашает вас
          отдохнуть, набраться сил и получить массу новых впечатлений в
          красивейшем месте Хакасии на озере Беле.
        </p>
        {/* <div className="about-button">
          <ButtonLink href={CompanyInfo.instagram}>подробнее</ButtonLink>
        </div> */}
      </div>
    </AboutStyled>
  )
}

export default About
