import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 6rem;

  .play {
    width: 0;
    height: 0;
    margin-left: 1.2rem;
    border-top: 3rem solid transparent;
    border-bottom: 3rem solid transparent;
    border-left: 4rem solid rgba(255, 255, 255, 0.7);
  }
`

const PlayIcon = () => (
  <Wrapper>
    <span className="play" />
  </Wrapper>
)

export default PlayIcon
