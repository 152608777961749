import * as React from 'react'
import About from '../components/main/About'
// import Baner from '../components/main/Baner'
import Services from '../components/main/Services'
import BookRoom from '../components/main/BookRoom'
import Reviews from '../components/main/Reviews'

const IndexPage = () => {
  return (
    <>
      {/* <Baner /> */}
      <About />
      <Services />
      <BookRoom />
      <Reviews />
    </>
  )
}

export default IndexPage
