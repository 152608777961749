import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'

import ButtonLink from '../ButtonLink'
import bg from '../../assets/images/reviews-bg.png'
import review1 from '../../assets/images/review-1.jpg'
import review2 from '../../assets/images/review-2.jpg'
import review3 from '../../assets/images/review-3.jpg'
import ArrowLeft from '../ArrowLeft'
import ArrowRight from '../ArrowRight'
import { useWindowSize } from '../../utils'
import { CompanyInfo } from '../../constants'

const ReviewsWrapper = styled.div`
  padding: 10rem 0 0 0;

  h1 {
    text-align: center;
    margin: 0 2rem;
  }

  .reviews-button {
    margin: 3rem 0 6rem 0;
    display: flex;
    justify-content: center;
  }
`

const SliderWrapper = styled.div`
  padding-bottom: 3rem;
  background: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -50rem;

  .slider-wrapper {
    max-width: 1300px;
    margin: 0 auto 0 auto;
  }

  .counter-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .arrows-margin {
    margin: 0;

    @media (min-width: 992px) {
      margin: 0 2rem 0 3rem;
    }
  }
`

const SlideStyled = styled.div`
  width: 95%;
  padding: 2rem;

  @media (max-width: 576px) {
    width: 86%;
  }

  @media (min-width: 992px) {
    width: 92%;
  }

  p {
    margin: 0;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 0 0 0;

    @media (min-width: 992px) {
      justify-content: flex-start;
    }
  }

  .avatar-frame {
    width: 10rem;
    height: 10rem;
    min-width: 10rem;
    border-radius: 50rem;
    border-width: 0.2rem;
    border-style: solid;
    border-color: var(--yellow);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4rem;

    @media (max-width: 576px) {
      margin-right: 3rem;
    }

    img {
      width: 8.4rem;
      height: 8.4rem;
      border-radius: 4.2rem;
      object-fit: cover;
    }
  }
`

const reviews = [
  {
    text: `Выбрались на выходные своей семьей. Обстановка приятная, персонал вежливый, кухня шикарная. Скучать не пришлось, сразу видно, что хозяин базы вкладывает в это место всю душу. Рекомендую отдых семейным парам, кто хочет провести время в спокойной обстановке!`,
    avatar: review1,
    name: 'Евгений Краснов'
  },
  {
    text: `Как здорово, что есть такое красивое место. Долго выбирала, где можно отдохнуть недалеко от дома и не пожалела о своем решении. Понравилось все. Шикарная кухня, пляж чистый, в номерах всегда убрано, а вечерние развлекательные программы просто бомба. Дети не скучали. Всегда была программа для детей. Рекомендую всем посетить эту базу и сама не раз еще съезжу туда.`,
    avatar: review2,
    name: 'Светлана Рябова'
  },
  {
    text: `Ездил с друзьями на озеро покататься на скутерах. Хорошо зарядились энергией. Понравилось то, что там есть охраняемая стоянка для скутера. Отличная баня и бильярд. Здорово, что можно взять мангал и отдохнуть на берегу. Есть возможность приобрести продукты и напитки, не привозить все с собой. Рекомендую всем там отдохнуть!`,
    avatar: review3,
    name: 'Александр Романов'
  }
]
const CustomSlide = ({ index, text, avatar, name, ...props }) => (
  <SlideStyled>
    <p>{text}</p>
    <div className="footer">
      <div className="avatar-frame">
        <img src={avatar} />
      </div>
      <span className="title">{name}</span>
    </div>
  </SlideStyled>
)

const SlideIndexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Cormorant SC Bold', serif;
  height: 8rem;
  min-height: 8rem;

  .index {
    font-size: 8rem;
    margin-right: 0.2rem;
  }

  .slash {
    font-size: 4rem;
  }

  .total {
    font-size: 2.5rem;
    margin-left: 0.5rem;
  }
`

const SlideIndex = ({ index }) => (
  <SlideIndexWrapper>
    <span className="index">{index + 1}</span>
    <span className="slash">/</span>
    <span className="total">{reviews.length}</span>
  </SlideIndexWrapper>
)

const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`

const Arrows = ({ onClickLeft, onClickRight }) => (
  <ArrowsWrapper>
    <ArrowLeft onClick={onClickLeft} />
    <ArrowRight onClick={onClickRight} />
  </ArrowsWrapper>
)

const Reviews = () => {
  const size = useWindowSize()

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: size.width >= 992 ? 2 : 1,
    slidesToScroll: 1
  }

  const sliderRef = useRef(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const onChangeSlide = (index: number) => setSlideIndex(index)

  const onLeft = () => {
    sliderRef?.current?.slickPrev()
  }

  const onRight = () => {
    sliderRef?.current?.slickNext()
  }

  return (
    <ReviewsWrapper>
      <h1>Что о нас говорят?</h1>
      <div className="reviews-button">
        <ButtonLink href={CompanyInfo.instagram}>все отзывы</ButtonLink>
      </div>
      <SliderWrapper>
        <div className="slider-wrapper">
          <Slider {...settings} afterChange={onChangeSlide} ref={sliderRef}>
            {reviews.map(({ text, avatar, name }, i) => (
              <CustomSlide index={i} {...{ text, avatar, name }} key={i} />
            ))}
          </Slider>
          <div className="counter-wrapper">
            <SlideIndex index={slideIndex} />
            <div className="arrows-margin">
              <Arrows onClickLeft={onLeft} onClickRight={onRight} />
            </div>
          </div>
        </div>
      </SliderWrapper>
    </ReviewsWrapper>
  )
}

export default Reviews
